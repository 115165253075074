import axios from 'axios';
import { useState, useRef, useEffect } from "react";
import useAuth from '../../hooks/useAuth';
import { useLogout } from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AppBar, IconButton, Toolbar, InputBase, Menu, MenuItem, Box, Typography, useMediaQuery, Button } from "@mui/material";
import searchIcon from "../../assets/search.png"
import translateIcon from '../../assets/translate.png'
import hamburgerIcon from '../../assets/hamburger.png'
import passwordIcon from "../../assets/password.png"
import logoutIcon from "../../assets/logout.png"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ConfirmationModal from '../../component/Modal';
import '../../css/global.css'


const Navbar = ({
    isSidebarOpen,
    setIsSidebarOpen,
    currentLocale,
    handleChange
}) => {

    const isNonMediumScreen = useMediaQuery("(min-width: 1024px)")

    const { username } = useAuth()

    const LanguageDropdown = () => {
        const ref = useRef(null)
        const [anchorEl, setAnchorEl] = useState(null)

        const navigate = useNavigate()

        const [isOpen, setIsOpen] = useState(false)
        const [openModal, setOpenModal] = useState(false);

        const Navigate = useNavigate()
        const { logout } = useLogout()

        const languageButtonStyle = {
            backgroundColor: '#001325F2',
            color: 'white'
        }

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
            setIsOpen(!isOpen)
        }

        const handleClose = () => {
            setAnchorEl(null)
            setIsOpen(false)
        }

        const handleOpenLogoutModal = () => {
            setOpenModal(true);
        };

        const handleChangePassword = () => {
            Navigate('/changepassword')
        }

        const handleLogout = () => {
            logout()
            navigate('/login')
        }

        const handleNoLogout = () => {
            setOpenModal(false)
        }

        useEffect(() => {
            const handleOutsideClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleClose()
                }
            }

            document.addEventListener("mousedown", handleOutsideClick)

            return () => {
                document.removeEventListener("mousedown", handleOutsideClick)
            }
        }, [ref])


        return (
            <Box className='flexBetween' ref={ref} gap="1.5rem">
                <Box
                    className='flexBetween'
                    backgroundColor="#ffffff"
                    borderRadius="9px"
                    border="1px solid #102D6780"
                    gap="1.5rem"
                    p={isNonMediumScreen ? "0.5rem " : "0.3rem"}
                >
                    {isNonMediumScreen ? (
                        <Box className='flexBetween' onClick={handleClick}>
                            <img src={translateIcon} alt="translate" style={{ width: '1.2rem', marginRight: '0.5rem' }} />
                            <span style={{ color: '#707070' }}><FormattedMessage id='hello' />, {username}</span>
                            <Box className='flexBetween' style={{ width: '2rem', justifyContent: 'center' }}>
                                {isOpen ? <KeyboardArrowUpIcon style={{ color: '#102D6780' }} onClick={() => handleClick()} /> : <KeyboardArrowDownIcon style={{ color: '#102D6780' }} onClick={() => handleClose()} />}
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            className='flexBetween' onClick={handleClick}>
                            <Box>
                                <img src={translateIcon} alt="translate" style={{ width: '1.2rem', marginRight: '0.5rem' }} />
                            </Box>
                            <Box>
                                {isOpen ? <KeyboardArrowUpIcon style={{ color: '#102D6780' }} onClick={() => handleClick()} /> : <KeyboardArrowDownIcon style={{ color: '#102D6780' }} onClick={() => handleClose()} />}
                            </Box>
                        </Box>
                    )}

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Box display='flex' padding='0 5px'>
                            <Button
                                style={{
                                    flex: 1,
                                    ...(currentLocale === 'en'
                                        ? languageButtonStyle
                                        : { ...languageButtonStyle, backgroundColor: '#ffffff', cursor: 'not-allowed', color: 'black' }),
                                }} onClick={(e) => handleChange(e.target.value)}
                                disabled={currentLocale === 'en'}
                                value="en"
                            >
                                En
                            </Button>
                            <Button
                                style={{
                                    flex: 1,
                                    ...(currentLocale === 'zh'
                                        ? languageButtonStyle
                                        : { ...languageButtonStyle, backgroundColor: '#ffffff', cursor: 'not-allowed', color: 'black' }),
                                }} onClick={(e) => handleChange(e.target.value)}
                                disabled={currentLocale === 'zh'}
                                value="zh"
                            >
                                Zh
                            </Button>
                        </Box>
                        <MenuItem onClick={handleChangePassword}>
                            <img src={passwordIcon} alt="search" style={{ width: '1.15rem', marginRight: '10px' }} />
                            <FormattedMessage id="change.password" />
                        </MenuItem>
                        <MenuItem onClick={handleOpenLogoutModal}>
                            <img src={logoutIcon} alt="logout" style={{ width: '1.5rem', marginRight: '10px' }} />
                            <FormattedMessage id='logout' />
                        </MenuItem>
                    </Menu>

                    {openModal && (
                        <ConfirmationModal
                            message="logout.confirmation.message"
                            onLogout={handleLogout}
                            onCancel={handleNoLogout}
                            onClose={handleNoLogout}
                        />
                    )}
                </Box>
            </Box>
        )
    }

    return (
        <AppBar
            sx={{
                position: "fixed",
                width: "100%",
                boxShadow: "none",
                backgroundColor: "white",
                p: "0.5rem"
            }}>
            <Toolbar sx={{ justifyContent: "space-between" }}>
                {/* left side */}
                <Box className='flexBetween' sx={{ marginLeft: isNonMediumScreen && isSidebarOpen ? '270px' : '0' }}>

                    <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={hamburgerIcon} alt="hamburger" style={{ width: "20px", height: "20px" }} onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
                    </Typography>

                    <Typography color='#285C8D' fontWeight="bold" fontSize='18px' paddingLeft='15px'>
                        <FormattedMessage id='angpao' />
                    </Typography>
                </Box>


                {/* right side */}
                <Box className='flexBetween' gap="1rem" >
                    <LanguageDropdown />
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar