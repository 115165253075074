import { LOCALES } from "./locales"

export const messages = {
    [LOCALES.ENGLISH]: {
        "angpao": "Lucky Angpao System",
        "login.username": "Username",
        "login.password": "Password",
        "login": "Login",
        "login.captcha": "Captcha",
        "login.username.error": "Please enter the username",
        "login.password.error": "Please enter the password",
        "login.captcha.error": "Please enter the captcha",
        "login.failed.title": "Error",
        "login.failed.message": "These credentials does not match with our records",
        "logout": "Logout",
        "logout.confirmation.message": "Are you sure to Logout?",
        "logout.yes": "Yes",
        "logout.cancel": "Cancel",

        // sidebar
        "dashboardNav": "Dashboard",
        "angpaoNav": "All Angpao",
        "logoutNav": "Logout",

        // navbar
        "hello": 'Hello',

        // change password
        "change.password": "Change Password",
        "current.password": "Current Password",
        "new.password": "New Password",
        "confirm.password": "Confirm Password",
        "success.message": "Your password has been changed successfully.",
        "go.to.home": "Go to home",
        "current.empty": "Please enter your current password.",
        "new.empty": "Please enter your new password",
        "confirm.empty": "Please confirm your new password.",
        "confirm.failed": "Your password does not match.",
        "save": "Save",

        // setting page
        "setting": "Setting",
        "submit": "Submit",

        // page: all angpao
        "angpaoId": "Angpao Id",
        "angpao_credit": "Angpao Credit",
        "num_receiver": "Number of Receiver",
        "angpao_owner": "Angpao Owner",
        "angpao_type": "Angpao Type",
        "createdAt": "Created Date",
        "details": "Detail",
        "valid_until": "Expiry Date",
        "receiver": "Receiver",
        "equal": "Equal",
        "random": "Random",
    },


    [LOCALES.CHINESE]: {
        "angpao": "幸运红包",
        "login.username": "登录名",
        "login.password": "密码",
        "login": "登录",
        "login.captcha": "验证码",
        "login.username.error": "请输入用户登录名",
        "login.password.error": "请输入登录密码",
        "login.captcha.error": "请输入验证码",
        "login.failed.title": "登陆失败",
        "login.failed.message": "您的用户名/密码不匹配。",
        "logout": "退出",
        "logout.confirmation.message": "确定要登出吗?",
        "logout.yes": "是",
        "logout.cancel": "否",

        // sidebar
        "dashboardNav": "仪表盘",
        "angpaoNav": "全部红包",
        "logoutNav": "登出",

        // navbar
        "hello": '您好',

        // change password
        "change.password": "更换密码",
        "current.password": "当前密码",
        "new.password": "新密码",
        "confirm.password": "确定新密码",
        "success.message": "密码已成功被更换。",
        "go.to.home": "首页",
        "current.empty": "请输入您现在的密码.",
        "new.empty": "请输入您的新密码",
        "confirm.empty": "请确认您的新密码",
        "confirm.failed": "您输入的密码不正确",
        "save": "储存",

        // setting page
        "setting": "设置",
        "submit": "提交",

        // page: all angpao
        "angpaoId": "序号",
        "angpao_credit": "红包总金额",
        "num_receiver": "领取人数",
        "angpao_owner": "创建人",
        "angpao_type": "红包类别",
        "createdAt": "创建日期",
        "details": "资料",
        "valid_until": "失效日期",
        "receiver": "收取人",
        "equal": "平分",
        "random": "随机",
    },


}