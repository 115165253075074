import jwtDecode from "jwt-decode";

// const useAuth = () => {
//     const token = JSON.parse(localStorage.getItem('user')).accessToken;
//     let isAdmin = false

//     if (token) {
//         const decoded = jwtDecode(token)

//         const { username, isAdmin } = decoded.UserInfo

//         return { username, isAdmin }
//     }
//     return { username: '', isAdmin }
// }

// export default useAuth

const useAuth = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let isAdmin = false;

    if (user && user.accessToken) {
        const decoded = jwtDecode(user.accessToken);
        const { username, isAdmin: userIsAdmin } = decoded.UserInfo;
        isAdmin = userIsAdmin;
        return { username, isAdmin };
    }

    return { username: '', isAdmin };
};

export default useAuth;
