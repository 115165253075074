import { Box, Typography } from "@mui/material"

const Header = ({ title }) => {
    return (
        <Box>
            <Typography
                color='#102D67BF'
                fontWeight='bold'
                fontSize='18px'
                sx={{ mb: '5px' }}
            >
                {title}
            </Typography>
        </Box>
    )
}

export default Header