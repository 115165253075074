import { Box } from "@mui/material"
import { useEffect } from "react"
import cheerio from 'cheerio'
import axios from 'axios'

const Dashboard = () => {

    const matahari = 'https://matahari365.club/';
    const perdana = 'https://bos007.net/cn/jackpot';
    const perdanaSelector = '#pagecontent-dummy > div.container > div:nth-child(1) > div.col-sm-8 > h1';
    const matahariSelector = 'p.jackpot-pool-total';

    useEffect(() => {
        async function fetchAPI() {
            try {

                console.log('useEffect start')

                const promises = [
                    axios.get(matahari),
                    axios.get(perdana),
                ];

                // Fetch data concurrently using Promise.all
                const [matahariResponse, perdanaResponse] = await Promise.all(promises);

                // Load data into Cheerio objects
                const $matahari = cheerio.load(matahariResponse.data);
                const $perdana = cheerio.load(perdanaResponse.data);

                // Extract and log data
                const matahariData = $matahari(matahariSelector).text();
                const perdanaData = $perdana(perdanaSelector).text();

                console.log('matahari:', matahariData);
                console.log('perdana:', perdanaData);

            } catch (error) {
                console.error(error);
            }
        }

        fetchAPI();
    }, []);

    return (
        <Box>
            dashboard
        </Box>
    )
}

export default Dashboard