import { Box, useMediaQuery } from '@mui/material'
import { useAuthContext } from '../../hooks/useAuthContext'
import Sidebar from '../global/Sidebar';
import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from '../../i18n/locales';
import { messages } from "../../i18n/messages"
import { Outlet } from 'react-router-dom';
import Navbar from '../global/Navbar';


const Layout = () => {

    const { user } = useAuthContext()

    const isNonMobile = useMediaQuery("(min-width: 1024px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const [currentLocale, setCurrentLocale] = useState(getInitialLocal())

    const handleChange = (locale) => {
        setCurrentLocale(locale)
        localStorage.setItem("locale", locale)
    }

    function getInitialLocal() {
        // getting stored items
        const savedLocale = localStorage.getItem('locale')
        return savedLocale || LOCALES.ENGLISH
    }

    return (
        <IntlProvider
            messages={messages[currentLocale]}
            locale={currentLocale}
            defaultLocale={LOCALES.ENGLISH}
        >
            <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
                {user && (
                    <Sidebar
                        isNonMobile={isNonMobile}
                        drawerWidth="270px"
                        isSidebarOpen={isSidebarOpen}
                        setIsSidebarOpen={setIsSidebarOpen}
                        currentLocale={currentLocale}
                    />
                )}
                <Box flexGrow={1} sx={{ paddingTop: '80px' }}>
                    {user && (
                        <Navbar
                            isSidebarOpen={isSidebarOpen}
                            setIsSidebarOpen={setIsSidebarOpen}
                            currentLocale={currentLocale}
                            handleChange={handleChange}
                        />
                    )}
                    <Outlet />
                </Box>
            </Box>
        </IntlProvider>
    )
}

export default Layout