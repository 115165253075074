import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"
import { ThemeProvider, CssBaseline, createTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { themeSettings } from "./theme";
import { useAuthContext } from "./hooks/useAuthContext";
import Layout from "./scenes/layout/Layout";
import Dashboard from "./scenes/Dashboard";
import AllAngpao from "./scenes/allAngpao";
import LoginPage from "./scenes/authentication/LoginPage";

function App() {
    const mode = useSelector((state) => state.global.mode)
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
    const { user } = useAuthContext()

    



    return (
        <div className="app">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={!user ? <LoginPage /> : <Navigate to="/allangpao" />} />
                            <Route path="dashboard" element={user ? <Dashboard /> : <Navigate to="/" />} />
                            <Route path="allangpao" element={user ? <AllAngpao /> : <Navigate to="/" />} />
                        </Route>
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
