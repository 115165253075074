import { Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery } from "@mui/material"
import { useAuthContext } from "../hooks/useAuthContext"
import Header from "../component/Header"
import { FormattedMessage, useIntl } from "react-intl"
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import axios from "axios"
import moment from "moment"
import '../css/angpaoDetail.css'
import CloseIcon from '@mui/icons-material/Close';

const AllAngpao = () => {
    const isNonMediumScreen = useMediaQuery("(min-width: 1200px")
    const dataGridWidth = useMediaQuery("(max-width: 1200px");

    const { user } = useAuthContext()

    const [selectedRowIds, setSelectedRowIds] = useState([])
    const [angpao, setAngpao] = useState([])

    const intl = useIntl()
    const equalTranslate = intl.formatMessage({ id: 'equal' })
    const randomTranslate = intl.formatMessage({ id: 'random' })

    const columns = [
        {
            field: 'rowIndex',
            headerName: 'No',
            minWidth: dataGridWidth ? 80 : 90,
        },
        {
            field: 'angpaoID',
            headerName: <FormattedMessage id="angpaoId" />,
            minWidth: dataGridWidth ? 170 : 300,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'angpao_credit',
            headerName: <FormattedMessage id="angpao_credit" />,
            valueFormatter: (params) => params.value ? params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : '',
            minWidth: dataGridWidth ? 190 : 200,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'angpao_owner',
            headerName: <FormattedMessage id="angpao_owner" />,
            cellClassName: "name-column--cell",
            minWidth: dataGridWidth ? 170 : 250,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'angpao_type',
            headerName: <FormattedMessage id="angpao_type" />,
            minWidth: dataGridWidth ? 170 : 230,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'createdAt',
            headerName: <FormattedMessage id="createdAt" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY h:mm:ss a"),
            minWidth: dataGridWidth ? 230 : 270,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'valid_until',
            headerName: <FormattedMessage id="valid_until" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY h:mm:ss a"),
            minWidth: dataGridWidth ? 230 : 270,
            editable: false,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'Details',
            headerName: <FormattedMessage id="details" />,
            minWidth: dataGridWidth ? 230 : 270,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Button
                    onClick={() => handleAngpaoDetails(params.row._id)}
                >
                    <FormattedMessage id="details" />
                </Button>
            )
        }
    ]

    const [pageState, setPageState] = useState({
        isLoading: false,
        angpao: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    const fetchAllAngpao = async () => {
        // if (user) {
        try {
            const { data } = await axios.get(`api/angpao/all?page=${pageState.page}&limit=${pageState.pageSize}`)

            const angpaoWithIndex = data.allAngpao.map((angpao, index) => ({
                ...angpao,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, angpao: angpaoWithIndex, total: data.pageTotal }))

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchAllAngpao()
    }, [pageState.page, pageState.pageSize])

    const [open, setOpen] = useState(false)
    const [angpaoDetails, setAngpaoDetails] = useState(null)

    const handleAngpaoDetails = async (angpaoID) => {
        try {
            const { data } = await axios.get(`api/angpao/detail?rowID=${angpaoID}`, {
                headers: { 'Authorization': `Bearer ${user.accessToken}` }
            })

            setOpen(true)
            setAngpaoDetails(data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleClose = () => {
        setOpen(false)
        setAngpaoDetails(null)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box m="20px">
            <Header
                title={<FormattedMessage id="angpaoNav" />}
                subtitle={<FormattedMessage id="angpaoNav" />}
            />
            <Box
                mt="5px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreen ? undefined : "span 12" },
                    "& .name-column--cell": {
                        color: '#ff7a38'
                    },
                }}
            >
                <Box
                    gridColumn="span 12"
                    height="70vh"
                    backgroundColor="#FFFFFF"
                    borderRadius="0.55rem"
                    className="defaultSection"
                >

                    <DataGrid
                        columns={columns}
                        rows={pageState.angpao}
                        getRowId={(row) => row._id}
                        paginationMode="server"
                        loading={pageState.isLoading}
                        rowCount={pageState.total}
                        initialState={{
                            pagination: { paginationModel: { pageSize: pageState.pageSize } },
                        }}
                        onPaginationModelChange={(pageState) => {
                            setPageState((old) => ({ ...old, page: pageState.page + 1, pageSize: pageState.pageSize }))
                        }}
                        pageSizeOptions={[50]}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        onSelectionModelChange={(ids) => setSelectedRowIds(ids)}
                        sx={{
                            border: 1,
                            borderColor: 'primary.light',
                            textAlign: 'left',
                            justifyContent: 'left',
                            '& .MuiDataGrid-row:hover': {
                                backgroundColor: 'transparent',
                            },
                            [`& .${gridClasses.row}.even`]: {
                                backgroundColor: '#EDF2F6'
                            },
                            '& .MuiDataGrid-row.Mui-selected:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    />

                    {/* <DataGrid
                        columns={columns}
                        rows={angpao}
                        getRowId={(row) => row._id}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 50 } },
                        }}
                        pageSizeOptions={[50]}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        onSelectionModelChange={(ids) => setSelectedRowIds(ids)}
                        sx={{
                            border: 1,
                            borderColor: 'primary.light',
                            textAlign: 'left',
                            justifyContent: 'left',
                            '& .MuiDataGrid-row:hover': {
                                backgroundColor: 'transparent',
                            },
                            [`& .${gridClasses.row}.even`]: {
                                backgroundColor: '#EDF2F6'
                            },
                            '& .MuiDataGrid-row.Mui-selected:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    /> */}

                </Box>

            </Box>

            <Box>
                <Modal open={open} onClose={handleClose}>
                    {angpaoDetails ? (
                        <Box className='detail-box'>
                            <Box className='header'>
                                <Box flexGrow={1} paddingLeft='40px' paddingBottom='15px' paddingTop='15px'>
                                    <Typography color='#285C8D'><FormattedMessage id="angpao_owner" /> : {angpaoDetails.angpao_owner}</Typography>
                                </Box>
                                <IconButton>
                                    <CloseIcon onClick={handleClose} />
                                </IconButton>
                            </Box>
                            <Box className='body'>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className="detail_title"><FormattedMessage id="angpaoId" /></Typography>
                                                </TableCell>
                                                <TableCell className="detail_value">{angpaoDetails.angpaoID}</TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className="detail_title"><FormattedMessage id="angpao_credit" /></Typography>
                                                </TableCell>
                                                <TableCell className="detail_value">RM {angpaoDetails.angpao_credit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className="detail_title"><FormattedMessage id="num_receiver" /></Typography>
                                                </TableCell>
                                                <TableCell className="detail_value">{angpaoDetails.num_receiver}</TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className="detail_title"><FormattedMessage id="angpao_type" /></Typography>
                                                </TableCell>
                                                <TableCell className="detail_value">
                                                    {/* {angpaoDetails.angpao_type === 'random' ? randomTranslate : equalTranslate}
                                                    {angpaoDetails.angpao_type === 'random' ? (
                                                        <FormattedMessage id='random' />
                                                    ) : (
                                                        <FormattedMessage id='equal' />
                                                    )} */}
                                                    {angpaoDetails.angpao_type}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className="detail_title"><FormattedMessage id="createdAt" /></Typography>
                                                </TableCell>
                                                <TableCell className="detail_value">
                                                    {`${new Date(angpaoDetails.createdAt).getDate()}/${new Date(angpaoDetails.createdAt).getMonth() + 1}/${new Date(angpaoDetails.createdAt).getFullYear()}, ${new Date(angpaoDetails.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}`}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className="detail_title"><FormattedMessage id="valid_until" /></Typography>
                                                </TableCell>
                                                <TableCell className="detail_value">
                                                    {`${new Date(angpaoDetails.valid_until).getDate()}/${new Date(angpaoDetails.valid_until).getMonth() + 1}/${new Date(angpaoDetails.valid_until).getFullYear()}, ${new Date(angpaoDetails.valid_until).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}`}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow className="borderedRow">
                                                <TableCell className="tableCellWithBorder">
                                                    <Typography className="detail_title">
                                                        <FormattedMessage id="receiver" />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="detail_value">
                                                    <div className="receiver_container">
                                                        <table>
                                                            <tbody className="receiver_table">
                                                                {angpaoDetails.angpao_claim
                                                                    .filter((claim) => claim.playerID)
                                                                    .map((claim) => (
                                                                        <tr key={claim.playerID}>
                                                                            <td>{claim.playerID}</td>
                                                                            <td> - RM {claim.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box className='ok-box'>
                                <Button variant="contained" className="ok-button" onClick={handleClose}>
                                    ok
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Typography>no data</Typography>
                        </Box>
                    )}
                </Modal>
            </Box>
        </Box>
    )
}

export default AllAngpao